// ######################
// Text field functions
// ######################
export function between(min: number, n: number, max: number): number {
  return Math.max(min, Math.min(n, max));
}

export function toStr(value: number): string {
  return value ? value.toString() : "";
}

export function onlyNumbers(input: string | number): string {
  if (typeof input === 'number') input = toStr(input);
  return input.replace(/\D+/g, "") || "0";
}

export function fixed(precision: number): number {
  return between(0, precision, 20);
}

export function addThousandSeparator(integer: string, separator: string): string {
  return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
}

export function joinIntegerAndDecimal(integer: string, decimal: string, separator: string): string {
  return decimal ? integer + separator + decimal : integer;
}

export function numbersToCurrency(numbers: string, precision: number): string {
  const exp = Math.pow(10, precision);
  const float = parseFloat(numbers) / exp;
  return float.toFixed(fixed(precision));
}

export function formatNumber(
  input: string | number,
  opt = {
    prefix: "",
    suffix: "",
    thousands: ",",
    decimal: ".",
    precision: 2,
  }
): string {
  if (typeof input === "number") {
    input = input.toFixed(fixed(opt.precision));
  }
  const negative = input.indexOf("-") >= 0 ? "-" : "";

  const numbers = onlyNumbers(input);
  const currency = numbersToCurrency(numbers, opt.precision);
  const parts = toStr(parseFloat(currency)).split(".");
  let integer = parts[0];
  const decimal = parts[1];
  integer = addThousandSeparator(integer, opt.thousands);
  return (
    negative +
    joinIntegerAndDecimal(integer, decimal, opt.decimal) +
    opt.suffix
  );
}

export function unformat(input: string, precision: number): number {
  const negative = input.indexOf("-") >= 0 ? -1 : 1;
  const numbers = onlyNumbers(input);
  const currency = numbersToCurrency(numbers, precision);
  return parseFloat(currency) * negative;
}

export function event(name: string): Event {
  const evt = document.createEvent("Event");
  evt.initEvent(name, true, true);
  return evt;
}

export function maskCPF(value: string): string {
  // faz a formatação do CPF
  if (typeof value === "string")
    return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, "$1.$2.$3-$4");
  return value;
}

export function unmaskCPF(value: string): string {
  // remove a formatação do CPF
  if (typeof value === "string")
    return value.replace(/\./g, "").replace(/-/g, "");
  return value;
}

export function maskCNPJ(value: string): string {
  // faz a formatação do CPF
  if (typeof value === "string")
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      "$1.$2.$3/$4-$5"
    );
  return value;
}

export function unmaskCNPJ(value: string): string {
  // remove a formatação do CPF
  if (typeof value === "string")
    return value
      .replace(/\./g, "")
      .replace(/-/g, "")
      .replace(/\//g, "");
  return value;
}

export function maskPhone(value: string): string {
  // faz a formatação do telefone
  if (typeof value === "string") {
    if (value.length === 10) {
      return value.replace(/^(\d{2})(\d{4})(\d{4}).*/, "($1) $2-$3");
    } else {
      return value.replace(/^(\d{2})(\d{5})(\d{4}).*/, "($1) $2-$3");
    }
  }
  return value;
}

export function unmaskPhone(value: string): string {
  // remove a formatação do telefone
  if (typeof value === "string")
    return value.replace(/[{()}]/g, "").replace(/\s/g, "");
  return value;
}

export function decimalBRZToNumber(str: string): number {
  if (typeof str !== "string") return str;
  return parseFloat(
    str
      .split(",")
      .map((a) => a.split(".").join(""))
      .join(".")
  );
}

export function cpfIsValid(cpf: string): boolean {
  // Verifica se cpf esta vazio
  if (!cpf) return false;
  // remove pontos e ifens
  cpf = cpf.replace(/\D/g, "");
  // verifica length e verifica se digitos são iguais
  if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let result = true;
  [9, 10].forEach((index) => {
    let sum = 0;
    let left = 0;

    cpf
      .split(/(?=)/)
      .splice(0, index)
      .forEach((el, i) => {
        sum += parseInt(el) * (index + 2 - (i + 1));
      });
    left = sum % 11;
    left = left < 2 ? 0 : 11 - left;
    if (left.toString() != cpf.substring(index, index + 1)) {
      result = false;
    }
  });
  return result;
}

export function cnpjIsValid(cnpj: string): boolean {
  if (!cnpj) return false;
  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes =
    typeof cnpj === "string" || Number.isInteger(cnpj) || Array.isArray(cnpj);
  // Elimina valor em formato inválido
  if (!validTypes) return false;
  // Guarda um array com todos os dígitos do valor
  const match = cnpj.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];
  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;
  // Elimina inválidos com todos os dígitos iguais
  const items = Array.from(new Set(numbers));
  if (items.length === 1) return false;
  // Cálculo validador
  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };
  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);
  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

// TODO: too simple
export function phoneIsValid(phone: string): boolean {
  if (!phone) return false;
  // remove pontos e parenteses
  phone = phone.replace(/[^0-9]/g, "");
  const match = phone.toString().match(/\d/g);
  // Verifica se todos os numeros são iguais
  // 11 mobile 10 residencial
  const numbers = Array.isArray(match) ? match.map(Number) : [];
  // pega numero sem ddd
  let noDDD: string[] | null | RegExpMatchArray = phone.toString().match(/\d/g);
  if (noDDD) noDDD = noDDD.slice(2);
  // Valida a quantidade de dígitos
  if (numbers.length < 10 || numbers.length > 11) {
    return false;
  }
  // Elimina inválidos com todos os dígitos iguais
  const items = Array.from(new Set(noDDD));
  if (items.length === 1) {
    return false;
  }
  return true;
}

export function getMinYaxis(min: number): number {
  const tens = Math.floor(min / 10)
  return tens * 10;
}

export function getMaxYaxis(max: number): number {
  const tens = Math.ceil(max / 10)

  return tens * 10;
}
