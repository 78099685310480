<template>
  <v-card max-height="410" class="mx-auto">
    <base-material-card
      color="primary"
      width="2600"
      style="background-color:white"
      flat
      icon="mdi-silo"
      title="Silo">
      <v-card color flat class="px-4 mt-4">
        <v-card-title color="white" class="pt-0">
          <v-row justify="space-between" align="start">
            <v-col id="list-crud" cols=10
              class="text-md-end pb-sm-0 text-sm-left py-sm-7 py-md-2">
              <div class="filters-wrapper">
                <div class="in-line mr-1 hidden-md-and-down">
                  <span>Filtros:</span>
                </div>
                <div class="in-line mx-2">
                  <v-radio-group v-model="siloOwnerdFilter" row>
                    <v-radio
                      v-for="(p, i) in owned"
                      :key="i"
                      :label="p.label"
                      :value="p.value"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div class="in-line mx-2">
                  <v-text-field
                      v-model="nameFiltered"
                      label="Nome"
                      clearable/>
                </div>
                <div class="in-line mx-2">
                  <agr-city-selector
                    v-model="cityFilterer"
                    label="Cidade"
                    clearable/>
                </div>
              </div>
            </v-col>
            <v-col cols=2 class="text-sm-right mb-sm-4">
              <v-btn
                large
                class="py-sm-0 py-2 font-weight-medium"
                color="primary"
                dark
                rounded
                :disabled="disableAdd"
                @click="openForm"
              >
                <!-- Larges screens -->
                <span class="hidden-md-and-down">Adicionar novo</span>
                <!-- Smalls screens -->
                <span class="hidden-lg-and-up">Novo</span>
              </v-btn>
              <v-alert
                v-if="disableAdd"
                border="top"
                colored-border
                type="warning"
                elevation="2"
                class="pb-0"
              >
                Adicione uma propriedade antes.
              </v-alert>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-resize="onResize">
            <v-skeleton-loader
              v-if="load"
              v-bind="crudSkelletonAttrs"
              type="table-heading@2, list-item-two-line"
              width="100%"/>
            <agr-data-table
              v-else
              :headers="headerFiltered"
              :items="silosFiltered"
              :loading="load"
              :btn-actions="true"
              resource="silo"
              @edit="edit"
              @confirmDelete="confirmDelete"
              @duplicate="duplicate">
              <!-- Itens slots -->
              <template v-slot:name="{item}">
                <span class="font-weight-medium">{{item.name}}</span>
              </template>
              <template v-slot:owned="{ item }">
                <span>{{ returnOwned(item) }}</span>
              </template>
            </agr-data-table>
          </v-row>
        </v-card-text>
      </v-card>
      <silo-form
        ref="form"
        v-model="dialog"
        :providers="providers"
        @updateResourceList="updateResourceList(results.objects, $event)"/>
    </base-material-card>
  </v-card>
</template>

<script>
import SiloForm from "./SiloForm";
import CrudMixins from "@/mixins/crud";
import { mapGetters } from "vuex";

export default {
  name: "Silo",
  mixins: [CrudMixins],
  components: {
    SiloForm
  },
  data() {
    return {
      dialog: false,
      disableAdd: false,
      load: false,
      providers: undefined,
      owned: [
        {label: 'Próprio', value: true},
        {label: 'Terceiro', value: false}
      ],
      headers: [
        {
          text: "Nome",
          tooltip: "Nome do fornecedor/cliente",
          align: "left",
          value: "name"
        },
        {
          text: "Endereço",
          tooltip: "Localização do Silo",
          align: "center",
          value: "address"
        },
        {
          text: "Cidade",
          tooltip: "Cidade da localização do silo",
          align: "center",
          value: "city.name"
        },
        {
          text: "Estado",
          tooltip: "Estado da localização do silo",
          align: "center",
          value: "city.state.name"
        },
        {
          text: "Propriedade",
          tooltip: "Propriedade do silo",
          align: "center",
          value: "owned"
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o silo",
          value: "actions",
          sortable: false
        }
      ],
      siloOwnerdFilter: undefined,
      nameFiltered: undefined,
      cityFilterer: undefined,
    }
  },
  computed: {
    ...mapGetters({
      states: "getStates",
    }),
    silosFiltered() {
      // criteria definida no crud mixin
      if(!this.criteria.length) return this.results.objects;
      // propotype definido no crud mixin
      return this.results.objects.dynamicFilter(this.criteria);
    },
    headerFiltered(){
      if(this.$vuetify.breakpoint.smAndDown) {
        return [this.headers[0], this.headers[2], this.headers[4], this.headers[this.headers.length - 1]]
      }
      return this.headers;
    }
  },
  methods: {
    searchSilos() {
      let payload = {
        resource: "provider",
        params: {
          my: true,
          checkDelete: true
        }
      };
      this.load = true;
      // Busca primeiros os clientes
      this.getResource(payload).then(result => {
        this.providers = result;
        // Depois os silos
        payload.resource = "silo";
        this.search(payload).then(() => {this.load = false;});
      });
    },
    edit(usilo) {
      this.$refs.form.toUpdate(usilo);
    },
    confirmDelete(dsilo) {
      this.setResource("silo");
      dsilo.is_deleted = true;
      dsilo.deleted_date = new Date().toISOString();
      this.update(dsilo)
        .then(() => {
          this.$alertSuccess("Silo deletedo com sucesso!");
          this.removeFromResourceList(this.results.objects, dsilo);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar o silo!");
        });
    },
    returnOwned(item) {
      return item.owned ? "Próprio" : "Terceiro"
    }
  },
  created() {
    this.searchSilos();
  },
  watch: {
    cityFilterer(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, 'city_id');
    },
    siloOwnerdFilter(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, 'owned');
    },
    nameFiltered(val) {
      // função do crud mixin
      this.createUpdateCriteria(val, 'name');
    }
  }
}
</script>

<style>

</style>