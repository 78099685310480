<template>
  <v-dialog
    v-model="show"
    mix-height="90vh"
    max-width="50vw"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    @keydown.esc="show = false">
    <v-card pt-3>
      <v-card-title class="form-header headline pa-2 font-weight-medium">
          {{ componentsText.action }} Silo
      </v-card-title>
      <v-card-text>
        <v-form ref="mainForm">
          <v-row>
            <v-col cols="6">
              <v-row>
                <v-col cols="12">
                  <v-radio-group v-model="siloOwnerd" row>
                    <v-radio
                      v-for="(p, i) in owned"
                      :key="i"
                      :label="p.label"
                      :value="p.value"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-scroll-y-transition group  tag="div">
                <v-row v-if="!siloOwnerd" :key="1">
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="silo.provider_id"
                      :items="providers"
                      label="Arrendador*"
                      item-text="name"
                      item-value="id"
                      :menu-props="{maxHeight: 250}"
                      :rules="[rules.required]"
                      clearable
                      auto-select-first/>
                  </v-col>
                </v-row>
                <v-row :key="2">
                  <v-col cols="12">
                    <v-text-field
                      v-model="silo.name"
                      label="Nome*"
                      :rules="[rules.required]"
                      required/>
                  </v-col>
                </v-row>
                <v-row :key="3">
                  <v-col cols="12">
                    <v-text-field
                      v-model="silo.address"
                      label="Logradouro*"
                      :rules="[rules.required]"
                      required/>
                  </v-col>
                </v-row>
                <v-row :key="4">
                  <v-col cols="12">
                    <v-text-field
                      v-model="silo.address_complement"
                      label="Complemento"
                      required/>
                  </v-col>
                </v-row>
                <v-row :key="5">
                  <v-col cols="12">
                    <agr-city-selector
                      v-model="silo.city_id"
                      label="Cidade*"
                      :rules="[rules.required]"
                      field-required/>
                  </v-col>
                </v-row>
                <v-row :key="6">
                  <v-col cols="6">
                    <v-text-field
                      v-model="silo.latitude"
                      label="Latitude"
                      required/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="silo.longitude"
                      label="Longitude"
                      required/>
                  </v-col>
                </v-row>
              </v-scroll-y-transition>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col>
                  <span class="font-weight-regular" style="color: var(--v-primary-base)">Dados técnicos (opcional)</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-currency-field
                    v-model="silo.capacity"
                    label="Capacidade"/>
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="silo.lost_factor"
                    label="Fator de perda"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="silo.silo_type_id"
                    :items="siloTypes"
                    label="Tipo do Silo"
                    item-text="name"
                    item-value="id"
                    :menu-props="{maxHeight: 250}"
                    clearable
                    auto-select-first/>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-col class="text-right firefox-align-end">
                  <v-btn
                    rounded
                    small
                    class="text-button"
                    :color="includeSiloType ? 'error' : 'primary'"
                    @click="includeSiloType = !includeSiloType">
                    <v-icon left>
                      {{ includeSiloType ? 'mdi-close' : 'mdi-plus' }}
                    </v-icon>
                    {{ includeSiloType ? 'fechar' : 'tipo de silo' }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition appear>
                <v-row v-if="includeSiloType">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          :disabled="loadSiloType"
                          v-model="silo_type.name"
                          label="Nome do tipo"
                          required/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-currency-field
                          v-model="silo_type.cost"
                          label="Quebra técnica"
                          :disabled="loadSiloType"
                          clearable/>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox
                          :disabled="loadSiloType"
                          v-model="silo_type.has_limitations"
                          label="Tem limitações"
                          :value="true"/>
                      </v-col>
                    </v-row>
                    <v-row justify="end">
                      <v-col class="text-right firefox-align-end">
                        <v-btn
                          color="primary"
                          class="text-button"
                          small
                          :loading="loadSiloType"
                          @click="saveSilotype">
                          Salvar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" no-gutters>
          <v-col class="text-right firefox-align-end">
            <v-btn
              color="error"
              @click="close">
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              @click="save">
              {{ componentsText.action }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixins from "@/mixins/form";

export default {
  name: "SiloForm",
  props: {
    providers: Array,
  },
  mixins: [FormMixins],
  data() {
    return {
      // Object: silo
      silo: undefined,
      // Object: silo_type
      silo_type: undefined,
      // model radio button
      siloOwnerd: true,
      // model form de tipo de silo
      includeSiloType: false,
      // load do insert do silo_type
      loadSiloType: false,
      siloTypes: [],
      owned: [
        {label: 'Próprio', value: true},
        {label: 'Terceiro', value: false}
      ],
      // defalut values
      defaultSilo: {
        name: undefined,
        capacity: undefined,
        lost_factor: undefined,
        address: undefined,
        address_complement: undefined,
        latitude: undefined,
        longitude: undefined,
        owned: true,
        city_id: undefined,
        growing_id: undefined,
        provider_id: undefined,
        ownership_id: undefined,
        silo_type_id: undefined,
        user_id: undefined
      },
      deafultSilo_type: {
        name: undefined,
        cost: undefined,
        has_limitations: false,
      }
    }
  },
  computed: {
    componentsText() {
      if (this.silo && this.silo.id) {
        return {
          action: "Atualizar",
          mSuccess: "Silo atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o silo",
        };
      } else {
        return {
          action: "Adicionar",
          mSuccess: "Silo inserido com sucesso!",
          mError: "Houve um problema ao inserir o silo",
        };
      }
    }
  },
  methods: {
    save() {
      if (this.validate()) {
        this.silo.is_deleted = false;
        this.silo.owned = this.siloOwnerd;
        this.silo.user_id = this.user.id;
        this.setResource("silo");
        this.saveResource(this.silo).then(response => {
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceList", response);
          this.close();
        }).catch(() => {
          this.$alertError(this.componentsText.mError)
        });
      }
    },
    initialize() {
      this.siloOwnerd = true;
      this.$nextTick(() => {
        this.silo = Object.assign({}, this.defaultSilo);
        this.silo_type = Object.assign({}, this.deafultSilo_type);
      });
    },
    async searchSiloType() {
      let payload = {
        resource: "silo_type",
        params: {
          my: true,
        }
      };
      await this.getResource(payload).then(result => {
        this.siloTypes = result;
      });
    },
    saveSilotype() {
      this.loadSiloType = true;
      this.setResource("silo_type");
      this.saveResource(this.silo_type).then(async () => {
        await this.searchSiloType();
        this.$alertSuccess("Tipo inserido com sucesso");
        this.includeSiloType = false;
      }).catch(() => {
        this.$alertError("Houve um erro ao inserir o tipo")
      });
    },
    toUpdate(item) {
      this.show = true;
      this.siloOwnerd = item.owned;
      this.$nextTick(() => {
        this.silo = Object.assign({}, item);
      });
    }
  },
  async created() {
    this.silo = Object.assign({}, this.defaultSilo);
    this.silo_type = Object.assign({}, this.deafultSilo_type);
    await this.searchSiloType();
  },
}
</script>

<style>

</style>