import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { dateISONotNull } from "@/utilsDate";
import { decimalBRZToNumber } from "@/utilsNumber";
import { cnpjIsValid, cpfIsValid, phoneIsValid } from "@/utilsText";

interface CommonFuncs {
  resetValidation?: () => void;
  validate?: () => boolean;
}

export default Vue.extend({
  props: {
    value: Boolean,
  },
  data: () => ({
    rules: {
      required: (value: string | undefined) => !!value || "Campo obrigatório.",
      norequired: () => true,
      nozero: (value: string | undefined) => {
        if (!value) return true;
        return !!decimalBRZToNumber(value) || "Campo obrigatório.";
      },
      email: (value: string | undefined) => {
        if (value) {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail inválido.";
        }
        return true;
      },
      cpf: (value: string | undefined) => {
        if (value) {
          return cpfIsValid(value) || "CPF inválido.";
        }
        return true;
      },
      cnpj: (value: string | undefined) => {
        if (value) {
          return cnpjIsValid(value) || "CNPJ inválido.";
        }
        return true;
      },
      phone: (value: string | undefined) => {
        if (value) {
          return phoneIsValid(value) || "Telefone inválido.";
        }
        return true;
      },
    },
  }),
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
      currencies: "getCurrencies",
      cities: "getCities",
      states: "getStates",
      growings: "getGrowings",
      harvests: "getHarvests",
      AllUnits: "getUnits",
      maturationsGroups: "getMaturationGroups",
      sale_reasons: "getSaleReason",
      outgoingCategories: "getOutgoingCategories",
      subOutgoingType: "getSubOutgoingType",
      budgets_result: "getResults",
      stateCalculations: "getCalculationState",
    }),
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapMutations(["setResource"]),
    ...mapActions({
      saveResource: "SAVE_UPDATE",
      getResource: "SIMPLE_SEARCH",
      deleteResource: "DELETE",
      myOwnerships: "MY_OWNERSHIPS",
      myLots: "MY_LOTS",
      search: "SEARCH_CRUD",
    }),
    dateISONotNull(date: string, time = true): string {
      return dateISONotNull(date, time);
    },
    initialize: (): void => {
      return;
    },
    resetValidation: (): void => {
      return;
    },
    close(): void {
      this.show = false;
      // initialize() deve vir antes de reset()
      this.initialize();
      this.reset();
    },
    reset(): void {
      const mForm = this.$refs["mainForm"] as CommonFuncs;
      if (mForm && mForm.resetValidation) mForm.resetValidation();
    },
    validate(): boolean {
      const mForm = this.$refs["mainForm"] as CommonFuncs;
      if (mForm && mForm.validate) return mForm.validate();
      return false;
    },
  },
});
