import Vue from "vue";
import Currency from "@/models/currency";
import { mapGetters, mapMutations, mapActions } from "vuex"; // mapState
import { ISearchResponse } from "@/types/store-types";

type CriteriaItem = {
  field: string;
  value: string;
};

interface ITableItem {
  id?: number;
  created_at?: string;
  updated_at?: string;
  currency?: Currency;
  value?: number;
  reference_value?: number;
  growing_id?: number;
  harvest_id?: number;
  ownership_id?: number;
}

interface FilterFields {
  harvest_id: "harvest_id";
  growing_id: "growing_id";
  ownership_id: "ownership_id";
  [key: string]: string;
}

// type FieldsType = keyof typeof FilterFields;

export default Vue.extend({
  data: () => ({
    dialog: false as boolean,
    tableHeight: "40vh" as string,
    localLoading: false as boolean,
    results: {} as ISearchResponse<any>,
    criteria: [] as CriteriaItem[],
    ownershipSelected: undefined as number | undefined,
    harvestSelected: undefined as number | undefined,
    growingSelected: undefined as number | undefined,
    // from skeleton
    crudSkelletonAttrs: {
      class: "mb-6",
      elevation: 0,
    },
    classesTableHeader:
      "font-weight-bold text-h4 text-md-h5 text-sm-subtitle-1" as string,
  }),
  methods: {
    ...mapMutations(["setResource"]),
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
      getResource: "SIMPLE_SEARCH",
      delete: "DELETE",
      update: "SAVE_UPDATE",
      myLots: "MY_LOTS",
    }),
    openForm(): void {
      this.dialog = true;
    },
    onResize(): void {
      if (window.innerHeight <= 900) {
        this.tableHeight = "40vh";
      } else if (window.innerHeight > 900 && window.innerHeight <= 1080) {
        this.tableHeight = "50vh";
      } else {
        this.tableHeight = "70vh";
      }
      if (this.isMobile) this.tableHeight = "35vh";
    },
    convertDollar<T extends ITableItem>(item: T): number | string {
      if (item.currency && item.value && item.reference_value) {
        if (item.currency.identifier === "D") {
          return item.value * item.reference_value;
        }
        return item.value;
      }
      return "NaN";
    },
    duplicate<T extends ITableItem>(item: T, resource: string): void {
      if (item.id) delete item.id;
      if (item.created_at) delete item.created_at;
      if (item.updated_at) delete item.updated_at;

      this.setResource(resource);
      this.update(item)
        .then((response) => {
          this.$alertSuccess("Item duplicado com sucesso");
          this.updateResourceList(this.results.objects, response);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao duplicar o item");
        });
    },
    createUpdateCriteria(value: string, field: string): void {
      // CRIA OU ATUALIZA o filtro nos criterios
      // busca se já existe o filtro
      const index = this.criteria.findIndex((c) => c.field === field);
      if (typeof value !== "undefined" && value !== null) {
        if (index > -1) {
          // se true atualiza
          this.criteria[index].value = value;
        } else {
          // se false cria o novo filtro
          this.criteria.push({
            field: field,
            value: value,
          });
        }
      } else {
        // remove o item quando o filtro limpa
        if (index > -1) this.criteria.splice(index, 1);
      }
    },
    // Atualiza um elemento dentro do array
    // usado logo após POST/PATCH na API
    updateResourceList<T extends ITableItem>(array: T[], item: T): void {
      const index = array.findIndex((element) => element.id === item.id);
      if (index > -1) this.$set(array, index, item);
      else array.push(item);
    },
    // Remove um elemento dentro do array
    // usado logo após DELETE/PATCH na API
    removeFromResourceList<T extends ITableItem>(array: T[], item: T): void {
      const index = array.findIndex((element) => element.id === item.id);
      if (index > -1) array.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      harvests: "getHarvests",
      growings: "getGrowings",
      user: "getLoggedUser",
    }),
    resourceFiltered() {
      return <T extends ITableItem>(): T[] => {
        if (
          (!this.harvestSelected ||
            typeof this.harvestSelected === "undefined") &&
          (!this.growingSelected || typeof this.growingSelected === "undefined")
        ) {
          return this.results.objects;
        }
        if (
          !this.harvestSelected ||
          typeof this.harvestSelected === "undefined"
        ) {
          return this.results.objects.filter(
            (f: T) => f.growing_id === this.growingSelected
          );
        }
        if (
          !this.growingSelected ||
          typeof this.growingSelected === "undefined"
        ) {
          return this.results.objects.filter(
            (f: T) => f.harvest_id === this.harvestSelected
          );
        }
        return this.results.objects.filter(
          (f: T) =>
            f.harvest_id === this.harvestSelected &&
            f.growing_id === this.growingSelected
        );
      };
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },

  beforeCreate() {
    Array.prototype.dynamicFilter = function <T extends FilterFields>(
      criterias: CriteriaItem[]
    ): T[] {
      // Cria uma nova prototipação para os arrays
      // Set our variables
      const matches: T[] = [];
      let count = 0;
      const matchesFilter = function (item: T) {
        // Recebe o item o array que esta sendo filtrado
        // e compara com os criterios (criterias)
        criterias.forEach((c) => {
          // Check pra ver se o item exite para comparar
          const key: string = c.field;
          if (item[key] !== "undefined" && item[key] !== null) {
            // checagem diferente para o tipo do item
            if (typeof c.value === "string") {
              if (item[key].toLowerCase().includes(c.value.toLowerCase())) {
                count++;
              }
            } else {
              if (c.value === item[key]) count++;
            }
          }
        });
        // If TRUE, then the current item in the array meets
        // all the filter criteria
        const result = count == criterias.length;
        count = 0;
        return result;
      };
      this.forEach((element) => {
        // Percorre os item do array filtrado
        // se o element passou por todos os filtros add ele no return
        if (matchesFilter(element)) {
          matches.push(element);
        }
      });

      return matches;
    };
  },
});
